import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { SectionHeader, SectionDivider } from 'src/components/pages/index';
import { useText, useLanguage } from 'src/components/TextContext';
import { Grid, Button, Typography } from '@material-ui/core';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import brochureSrc from './BROCHURE DRAGON CLUSTER.pdf';
import esBrochure00 from '../../../../images/brochure/esBrochure00.jpg';
import esBrochure01 from '../../../../images/brochure/esBrochure01.jpg';
import esBrochure02 from '../../../../images/brochure/esBrochure02.jpg';
import esBrochure03 from '../../../../images/brochure/esBrochure03.jpg';
import esBrochure04 from '../../../../images/brochure/esBrochure04.jpg';
import esBrochure05 from '../../../../images/brochure/esBrochure05.jpg';
import esBrochure06 from '../../../../images/brochure/esBrochure06.jpg';
import esBrochure07 from '../../../../images/brochure/esBrochure07.jpg';
import esBrochure08 from '../../../../images/brochure/esBrochure08.jpg';
import esBrochure09 from '../../../../images/brochure/esBrochure09.jpg';
import esBrochure10 from '../../../../images/brochure/esBrochure10.jpg';
import esBrochure11 from '../../../../images/brochure/esBrochure11.jpg';
import esBrochure12 from '../../../../images/brochure/esBrochure12.jpg';
import esBrochure13 from '../../../../images/brochure/esBrochure13.jpg';
import esBrochure14 from '../../../../images/brochure/esBrochure14.jpg';

import enBrochure00 from '../../../../images/brochure/enBrochure00.jpg';
import enBrochure01 from '../../../../images/brochure/enBrochure01.jpg';
import enBrochure02 from '../../../../images/brochure/enBrochure02.jpg';
import enBrochure03 from '../../../../images/brochure/enBrochure03.jpg';
import enBrochure04 from '../../../../images/brochure/enBrochure04.jpg';
import enBrochure05 from '../../../../images/brochure/enBrochure05.jpg';
import enBrochure06 from '../../../../images/brochure/enBrochure06.jpg';
import enBrochure07 from '../../../../images/brochure/enBrochure07.jpg';
import enBrochure08 from '../../../../images/brochure/enBrochure08.jpg';
import enBrochure09 from '../../../../images/brochure/enBrochure09.jpg';
import enBrochure10 from '../../../../images/brochure/enBrochure10.jpg';
import enBrochure11 from '../../../../images/brochure/enBrochure11.jpg';
import enBrochure12 from '../../../../images/brochure/enBrochure12.jpg';
import enBrochure13 from '../../../../images/brochure/enBrochure13.jpg';
import enBrochure14 from '../../../../images/brochure/enBrochure14.jpg';

const esBrochureImgs = [
  esBrochure00,
  esBrochure01,
  esBrochure02,
  esBrochure03,
  esBrochure04,
  esBrochure05,
  esBrochure06,
  esBrochure07,
  esBrochure08,
  esBrochure09,
  esBrochure10,
  esBrochure11,
  esBrochure12,
  esBrochure13,
  esBrochure14,
];

const enBrochureImgs = [
  enBrochure00,
  enBrochure01,
  enBrochure02,
  enBrochure03,
  enBrochure04,
  enBrochure05,
  enBrochure06,
  enBrochure07,
  enBrochure08,
  enBrochure09,
  enBrochure10,
  enBrochure11,
  enBrochure12,
  enBrochure13,
  enBrochure14,
];

export const BrochureContent = () => {
  const t = useText();
  const [photoIndex, setPhotoIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const { currentLanguage } = useLanguage();

  const images = currentLanguage == 'es' ? esBrochureImgs : enBrochureImgs;

  const BrochureImg = ({ index }) => (
    <div
      tabIndex={0}
      css={css`
        margin: auto;
      `}
    >
      <img
        css={css`
          cursor: pointer;
          width: 100%;
        `}
        src={images[index]}
        onClick={() => {
          setOpen(true);
          setPhotoIndex(index);
        }}
      />
    </div>
  );

  return (
    <div>
      <SectionHeader>{t.brochure.title}</SectionHeader>
      <SectionDivider />

      <div
        css={css`
          display: flex;
          width: 100%;
          justify-content: center;
        `}
      >
        <a
          href={brochureSrc}
          target="_blank"
          download
          css={css`
            text-decoration: none;
          `}
        >
          <Button variant='contained' color='primary'>
            <Typography
              css={css`
                font-weight: bold;
              `}
            >
              {t.brochure.downloadButton}
            </Typography>
          </Button>
        </a>
      </div>
      {open && (
        <Lightbox
          reactModalStyle={{ overlay: { zIndex: 2000 } }}
          mainSrc={images[photoIndex]}
          onCloseRequest={() => setOpen(false)}
        />
      )}
      <Grid
        container
        spacing={2}
        css={css`
          padding: 1rem;
        `}
      >
        {images.map((x, i) => (
          <Grid item xs={12} key={i}>
            <BrochureImg index={i} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default BrochureContent;
