import React from 'react';
import { css } from '@emotion/core';
import { Layout, Main } from 'src/components/layout';
import { RootWrapper } from 'src/components/RootWrapper';
import { IndexHeader, IndexFooter } from 'src/components/pages/index';
import { BrochureContent } from 'src/components/pages/brochure';
import { TextContextProvider } from 'src/components/TextContext';

import esTextCommon from '../../text/es/common.yaml';
import esTextBrochure from '../../text/es/brochure.yaml';
import enTextCommon from '../../text/en/common.yaml';
import enTextBrochure from '../../text/en/brochure.yaml';

const esText = { ...esTextCommon, ...esTextBrochure };
const enText = { ...enTextCommon, ...enTextBrochure };
const text = {
  es: esText,
  en: enText,
};

const QuienesSomosPage = ({ location }) => {
  return (
    <RootWrapper>
      <TextContextProvider text={text}>
        <Layout>
          <IndexHeader backgroundVisibility={1} location={location} />
          <Main
            css={css`
              margin-top: 6rem;
            `}
          >
            <BrochureContent />
          </Main>
          <IndexFooter />
        </Layout>
      </TextContextProvider>
    </RootWrapper>
  );
};

export default QuienesSomosPage;
